.gpt3__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--color-footer);
}

.gpt3__footer-heading {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}

.gpt3__footer-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 64px;
  line-height: 75px;
  text-align: center;
  letter-spacing: -0.04em;
}

.gpt3__footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  border: 1px solid #ffffff;
  text-align: center;

  margin-bottom: 10rem;
  cursor: pointer;
}
.gpt3__footer-btn p {
  box-sizing: border-box;
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  word-spacing: 2px;
}

.gpt3__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;

  width: 100%;
  text-align: left;
}

.gpt3__footer-links div {
  width: 250px;
  margin: 1rem;
}

.gpt3__footer-links_logo {
  display: flex;
  flex-direction: column;
}

.gpt3__footer-links_logo img {
  width: 118px;
  height: 30px;

  margin-bottom: 1rem;
}

.gpt3__footer-links_logo p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.gpt3__footer-links_div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.gpt3__footer-links_div h4 {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;

  margin-bottom: 1rem;
}

.gpt3__footer-links_div p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;

  margin: 0.5rem 0;
  cursor: pointer;
}

.gpt3__footer-copyright {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}

.gpt3__footer-copyright p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 14px;

  color: #ffffff;
}

@media screen and (max-width: 850px){
    .gpt3__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px){
    .gpt3__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-links div {
       margin: 1rem 0;
    }

    .gpt3__footer-heading p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px){
    .gpt3__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }

    .gpt3__footer-links div {
       margin: 1rem 0;
    }
}